import * as React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import { Main } from "../styles";
import HeaderBackground from "../components/HeaderBackground";
import Header from "../components/Header";
import { SnackbarProvider } from "notistack";

const IndexPage = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <Layout>
        <Header />
        <HeaderBackground title="CONTACT" />
        <Main>
          <Contact />
        </Main>
        <Footer />
      </Layout>
    </SnackbarProvider>
  );
};

export default IndexPage;
