import React, { useState } from "react";
import styled from "styled-components";
import Iframe from "react-iframe";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withSnackbar } from "notistack";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Contact = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [contactState, setContactState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const onNameChange = (event) => {
    setContactState({ ...contactState, name: event.target.value });
  };

  const onEmailChange = (event) => {
    setContactState({ ...contactState, email: event.target.value });
  };

  const onMsgChange = (event) => {
    setContactState({ ...contactState, message: event.target.value });
  };

  const submitEmail = (e) => {
    e.preventDefault();
    try {
      axios({
        method: "POST",
        url: "https://admin.darwenmosque.co.uk:8090/contact",
        data: contactState,
      }).then((response) => {
        props.enqueueSnackbar(response.data.message);
        setContactState({
          name: "",
          email: "",
          message: "",
        });
        setOpen(true);
        // if (response.data.status === "success") {
        //   alert("Message Sent.");
        //   this.resetForm();
        // } else if (response.data.status === "fail") {
        //   alert("Message failed to send.");
        // }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Request sent successfully
        </Alert>
      </Snackbar>
      <Grid>
        <div>
          <Title>CONTACT INFO</Title>
          <h4>Madina Masjid Darwen</h4>
          <p>21-23 Victoria Street</p>
          <p>Darwen, BB3 3HB</p>
          <p>Email address: info@darwenmosque.co.uk</p>
          <p>Imam (Luqman Musa): 07833 891942</p>
          <p>Chairman (Imran Ilyas): 07912 781392</p>
        </div>
        <div>
          <Title>Get in Touch</Title>
          <br />
          <p>Have questions about ICA programs or services? Let us help.</p>
          <p>
            Use our contact form and we’ll get back to you as soon as possible.
          </p>
        </div>
      </Grid>
      <Grid>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2362.3251143382213!2d-2.467527583873292!3d53.69465268005347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487ba03e64a9bb7b%3A0x287afddf8ef4db26!2sDarwen%20Madina%20Masjid!5e0!3m2!1sen!2s!4v1595931009734!5m2!1sen!2s"
          width="100%"
          height="450px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />
        <form id="contact-form" onSubmit={submitEmail}>
          <div className="form-group">
            <div className="row">
              <div className="col-md-6" style={{ paddingBottom: "20px" }}>
                <TextField
                  placeholder="Name"
                  id="name"
                  type="text"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={contactState.name}
                  onChange={onNameChange}
                />
              </div>
              <div className="col-md-6" style={{ paddingBottom: "20px" }}>
                <TextField
                  placeholder="Email"
                  id="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  value={contactState.email}
                  onChange={onEmailChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group" style={{ paddingBottom: "20px" }}>
            <TextField
              placeholder="Message"
              id="message"
              multiline
              rows={4}
              label="Message"
              variant="outlined"
              fullWidth
              required
              value={contactState.message}
              onChange={onMsgChange}
            />
          </div>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </form>
      </Grid>
    </Container>
  );
};

export default withSnackbar(Contact);

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  background-color: #fff;
  padding: 4% 12%;
`;

const Button = styled.button`
  padding: 10px;
  background: var(--base-color);
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;
